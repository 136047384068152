import {
  description,
  mrAuto,
  mlAuto,
  section,
  container,
} from "assets/jss/material-kit-pro-react.jsx"
import imagesStyles from "assets/jss/material-kit-pro-react/imagesStyles.jsx"

const imgRaised = imagesStyles.imgRaised
const rounded = imagesStyles.imgRounded
const imgFluid = imagesStyles.imgFluid

const aboutImagesStyle = (theme) => ({
  description,
  mrAuto,
  mlAuto,
  container,
  section: {
    ...section,
    padding: "40px 0 80px 0",
  },
  aboutImages: {
    "& img": {
      margin: "20px 0px",
    },
  },
  aboutImage: {
    ...imgRaised,
    ...imgFluid,
    ...rounded,
    height: "200px",
    [theme.breakpoints.down("sm")]: {
      height: "150px",
    },
    [theme.breakpoints.down("sm")]: {
      height: "100px",
    },
    [theme.breakpoints.down("xs")]: {
      height: "200px",
    },
  },
  aboutImageWrapper: {
    height: "200px",
    margin: "1rem",
    [theme.breakpoints.down("sm")]: {
      height: "150px",
    },
    [theme.breakpoints.down("sm")]: {
      height: "100px",
    },
    [theme.breakpoints.down("xs")]: {
      height: "200px",
    },
  },
})

export default aboutImagesStyle
