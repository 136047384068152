// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import aboutImagesStyle from "assets/jss/material-kit-pro-react/views/aboutPageSections/aboutImagesStyle.jsx";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
// gatsby libraries
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import Fade from "react-reveal/Fade";

// aboutImages
const useStyles = makeStyles(aboutImagesStyle)

export default function AboutImages() {
  const {
    aboutImage1,
    aboutImage2,
    aboutImage3,
    aboutImage4,
    aboutImage5,
    aboutImage6,
  } = useStaticQuery(graphql`query getAboutPhotosImages {
  aboutImage1: file(relativePath: {eq: "about/about-1.jpg"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  aboutImage2: file(relativePath: {eq: "about/about-2.jpg"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  aboutImage3: file(relativePath: {eq: "about/about-3.jpg"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  aboutImage4: file(relativePath: {eq: "about/about-4.jpg"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  aboutImage5: file(relativePath: {eq: "about/about-5.jpg"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  aboutImage6: file(relativePath: {eq: "about/about-6.jpg"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
}
`)
  const classes = useStyles()
  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <GridContainer>
          <GridItem md={4} sm={4}>
            <div className={classes.aboutImageWrapper}>
            <Fade delay={200}>
              <GatsbyImage
                image={aboutImage1.childImageSharp.gatsbyImageData}
                className={classes.aboutImage}
                alt="About Image 1" />
                </Fade>
            </div>
          </GridItem>
          <GridItem md={4} sm={4}>
            <div className={classes.aboutImageWrapper}>
            <Fade delay={300}>
              <GatsbyImage
                image={aboutImage2.childImageSharp.gatsbyImageData}
                className={classes.aboutImage}
                alt="About Image 2" />
                </Fade>
            </div>
          </GridItem>
          <GridItem md={4} sm={4}>
            <div className={classes.aboutImageWrapper}>
            <Fade delay={400}>
              <GatsbyImage
                image={aboutImage3.childImageSharp.gatsbyImageData}
                className={classes.aboutImage}
                alt="About Image 3" />
                </Fade>
            </div>
          </GridItem>
          <GridItem md={4} sm={4}>
            <div className={classes.aboutImageWrapper}>
            <Fade delay={500}>
              <GatsbyImage
                image={aboutImage4.childImageSharp.gatsbyImageData}
                className={classes.aboutImage}
                alt="About Image 4" />
                </Fade>
            </div>
          </GridItem>
          <GridItem md={4} sm={4}>
            <div className={classes.aboutImageWrapper}>
            <Fade delay={600}>
              <GatsbyImage
                image={aboutImage5.childImageSharp.gatsbyImageData}
                className={classes.aboutImage}
                alt="About Image 5" />
                </Fade>
            </div>
          </GridItem>
          <GridItem md={4} sm={4}>
            <div className={classes.aboutImageWrapper}>
            <Fade delay={700}>
              <GatsbyImage
                image={aboutImage6.childImageSharp.gatsbyImageData}
                className={classes.aboutImage}
                alt="About Image 6" />
                </Fade>
            </div>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
